import { GetStartedSteps as GetStartedStepsProps } from "@models/get-started-steps";
import React, { FC, useState } from "react";
import { motion, AnimatePresence, color } from "motion/react";
import "./get-started-steps.scss";
import BaseSvg from "@components/base/svg/svg";
import { stagger } from "motion";

interface Props {
  data: GetStartedStepsProps;
}

export const GetStartedSteps: FC<Props> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (index: number) => {
    if (index === activeIndex) {
      return;
    }
    setActiveIndex(index);
  };

  const renderContent = (step: GetStartedStepsProps["steps"][number]) => {
    if (Array.isArray(step.content)) {
      return (
        <ul>
          {step.content.map((item, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </ul>
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: step.content }} />;
  };

  return (
    <div className="get-started-steps">
      <h2 className="heading">{data.title}</h2>

      <div className="desktop-view">
        <div className="steps-list">
          {data.steps.map((step, index) => (
            <div
              key={index}
              className={`step-item ${activeIndex === index ? "active" : ""}`}
              onClick={() => handleSelect(index)}
            >
              <div className="step-number">{index + 1}</div>
              <div className="step-title">{step.title}</div>
            </div>
          ))}
        </div>

        <div className="step-details">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeIndex}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              className="step-detail-content"
            >
              <BaseSvg name={data.steps[activeIndex].icon} />
              <h3>{data.steps[activeIndex].title}</h3>
              {renderContent(data.steps[activeIndex])}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      <div className="mobile-view">
        {data.steps.map((step, index) => (
          <motion.div key={index} className="accordion-item">
            <div
              className="accordion-header"
              onClick={() => handleSelect(index)}
            >
              <motion.div
                animate={{
                  color: activeIndex === index ? "#fff" : "#989898",
                  backgroundColor:
                    activeIndex === index ? "#9c21e8" : "transparent",
                  border:
                    activeIndex === index
                      ? "1px solid #9c21e8"
                      : "1px solid #989898",
                }}
                className="step-number"
              >
                {index + 1}
              </motion.div>
              <motion.div
                animate={{
                  color: activeIndex === index ? "#fff" : "#989898",
                  fontSize: activeIndex === index ? "1.3rem" : "1.2rem",
                }}
                className="step-title"
              >
                {step.title}
              </motion.div>
            </div>

            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={
                activeIndex === index
                  ? { opacity: 1, height: "auto", margin: "0 0 2rem" }
                  : { opacity: 0, height: 0, margin: "0rem 0" }
              }
              transition={{
                opacity: { duration: 0.2 },
                height: { duration: 0.3 },
              }}
              className="accordion-content"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={
                  activeIndex === index
                    ? { opacity: 1, y: 0 }
                    : { opacity: 0, y: "-10%", pointerEvents: "none" }
                }
                transition={{
                  delay: activeIndex === index ? 0.3 : 0,
                  duration: 0.3,
                }}
                className="step-detail-content"
              >
                <BaseSvg name={step.icon} />
                {renderContent(step)}
              </motion.div>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};
