import Access from "@components/blocks/access/access";
import Promo from "@components/blocks/promo/promo";
import Advantages from "@components/blocks/advantages/advantages";
import Partners from "@components/blocks/partners/partners";
import News from "@components/blocks/news/news";

import { useMainStore } from "@store/main";
import { availableAssets, getStartedSteps } from "@data";

import "./main.scss";
// import { AlternativeAssetsBlock } from "@components/blocks/alternative-assets/Component";
import { GetStartedSteps } from "@components/blocks/get-started-steps/get-started-steps";
import Alternative from "@components/blocks/alternative/alternative";

function Main(): JSX.Element {
  if (!useMainStore) {
    throw new Error("useMainStore must be used within a MainProvider");
  }
  const { item } = useMainStore();

  if (!item) {
    return <div>The Main page data has not been uploaded!</div>;
  }

  return (
    <main className="main">
      <Promo data={item.promo} />
      <Access data={item.access} />
      <Alternative data={item.alternative} />
      {/* <AlternativeAssetsBlock data={availableAssets} /> */}
      <GetStartedSteps data={getStartedSteps} />
      <Advantages data={item.advantages} />
      <News data={item.news} />
      <Partners data={item.partners} />
    </main>
  );
}

export default Main;
