import { WhyContent } from "@models/sections";
import "./why.scss";

interface Props {
  data: WhyContent;
}

function Why({ data }: Props): JSX.Element {
  if (!data) {
    return <div>There is no data</div>;
  }

  const { imgLarge, imgMedium, imgSmall, title, cards } = data;

  const whyList = cards.map((card) => (
    <li className={"why__list-item"} key={card.title}>
      <div className={"why-card"}>
        <h3
          className={"why-card__title"}
          dangerouslySetInnerHTML={{ __html: card.title }}
        />
        <div
          className={"why-card__text"}
          dangerouslySetInnerHTML={{ __html: card.text }}
        />
      </div>
    </li>
  ));

  return (
    <section className="why">
      <div className={"why__bg"} />
      <div className="why__inner">
        <h2
          className="h2 why__title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>
        <ul className={"why__list"}>{whyList}</ul>
      </div>
      {/* {
        imgLarge &&
        <picture className='why__image'>
          {
            typeof imgMedium === 'object' &&
            <source srcSet={imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof imgSmall === 'object' &&
            <source srcSet={imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          }
          {
            typeof imgLarge === 'object' &&
            <img
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      } */}
    </section>
  );
}

export default Why;
